import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from "@angular/router";
import { IdentityService } from "./identity.service";

@Injectable({
    providedIn: "root",
})
export class AuthenticatedGuard {
    constructor(
        private identityService: IdentityService,
        private router: Router,
    ) { }

    public canActivateChild(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot,
    ): ReturnType<CanActivateChildFn> {
        if (this.identityService.token) {
            return true;
        }

        this.router.navigate(["/welcome"]);
        return false;
    }
}
