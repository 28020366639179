import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import * as Sentry from "@sentry/angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CreateMeetingPageComponent } from "./create-meeting-page/create-meeting-page.component";
import { DebugPageComponent } from "./debug-page/debug-page.component";
import { FooterComponent } from "./footer/footer.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { HotkeyDirective } from "./hotkey.directive";
import { IdentityInterceptor } from "./identity.interceptor";
import { InsertLinkPageComponent } from "./insert-link-page/insert-link-page.component";
import { LoadingDirective } from "./loading.directive";
import { LoginPageComponent } from "./login-page/login-page.component";
import { MessageBoxComponent } from "./message-box/message-box.component";
import { SettingsPageComponent } from "./settings-page/settings-page.component";
import { TaskpanePageComponent } from "./taskpane-page/taskpane-page.component";
import { ViewMeetingPageComponent } from "./view-meeting-page/view-meeting-page.component";
import { WelcomePageComponent } from "./welcome-page/welcome-page.component";

@NgModule({
    declarations: [
        AppComponent,
        TaskpanePageComponent,
        WelcomePageComponent,
        HomePageComponent,
        CreateMeetingPageComponent,
        InsertLinkPageComponent,
        ViewMeetingPageComponent,
        LoginPageComponent,
        SettingsPageComponent,
        FooterComponent,
        MessageBoxComponent,
        LoadingDirective,
        DebugPageComponent,
        HotkeyDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: IdentityInterceptor, multi: true },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
