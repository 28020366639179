import { Component, Input } from "@angular/core";

export type MessageStatus = "success" | "error" | "warning";

@Component({
    selector: "adapt-message-box",
    templateUrl: "./message-box.component.html",
})
export class MessageBoxComponent {
    @Input() public status?: MessageStatus;
    @Input() public message?: string;
}
