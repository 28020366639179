import { Component } from "@angular/core";
import { BaseWithMessageComponent } from "app/base-with-message/base-with-message.component";
import { DialogEvent } from "app/dialog.service";
import { IdentityService } from "app/identity.service";
import { catchError, finalize } from "rxjs/operators";

@Component({
    selector: "adapt-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent extends BaseWithMessageComponent {
    public email = "";
    public password = "";

    public loading = false;

    constructor(private identityService: IdentityService) {
        super();
    }

    public login() {
        this.loading = true;
        this.identityService.login(this.email, this.password)
            .pipe(
                catchError(() => this.handleException(new Error("Failed to log in. The user may not exist or the password may be invalid."))),
                finalize(() => this.loading = false),
                this.takeUntilDestroyed(),
            ).subscribe((credentials) => {
                this.setMessage("success", "Successfully logged in");
                const message = { event: DialogEvent.LogIn, body: credentials };
                Office.context.ui.messageParent(JSON.stringify(message));
            });
    }
}
