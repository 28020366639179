<a routerLink="/home"
   class="link-button">
    <i class="fal fa-chevron-left"></i> Home
</a>

<div>
    <h2>Build Details</h2>

    <p>Build Date: {{buildDetails.buildDate}}</p>
    <p>Build Time: {{buildDetails.buildTime}}</p>
    <p>Source Date: {{buildDetails.sourceDate}}</p>
    <p>Source Branch: {{buildDetails.sourceBranch}}</p>
    <p>Source Hash: {{buildDetails.sourceHash}}</p>
</div>

<button (click)="createError()">Throw error</button>
