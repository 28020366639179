import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BaseWithMessageComponent } from "app/base-with-message/base-with-message.component";
import { DataService } from "app/data.service";
import { Meeting } from "app/models/meetings.model";
import { Team } from "app/models/team.model";
import { OutlookService } from "app/outlook.service";
import { EMPTY, of } from "rxjs";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-insert-link-page",
    templateUrl: "./insert-link-page.component.html",
})
export class InsertLinkPageComponent extends BaseWithMessageComponent implements OnInit {
    public teams: Team[] = [];
    public meetings: Meeting[] = [];

    public teamId?: number;
    public meetingId?: number;

    public fetching = true;
    public sending = false;

    constructor(
        private outlookService: OutlookService,
        private dataService: DataService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
    ) {
        super();

        this.dataService.organisationId$
            .pipe(
                tap(() => this.teamId = undefined),
                tap(() => this.meetingId = undefined),
                switchMap(() => this.updateTeams()),
                switchMap(() => this.updateMeetings()),
                tap(() => this.changeDetectorRef.detectChanges()),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }

    public ngOnInit() {
        this.fetching = true;
        this.updateTeams()
            .pipe(
                switchMap(() => this.updateMeetings()),
                finalize(() => this.fetching = false),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }

    public async insertLink() {
        this.sending = true;
        this.dataService.createMeetingLink(this.teamId!, this.meetingId!)
            .pipe(
                switchMap((link) => this.outlookService.insertMeetingLink(link)),
                catchError((error) => this.handleException(error)),
                finalize(() => this.sending = false),
                this.takeUntilDestroyed(),
            )
            .subscribe(async () => {
                this.setMessage("success", "A link to the meeting has been added to the Outlook meeting description.");
                const queryParams = await this.outlookService.getCurrentMeeting();
                await this.router.navigate(["/view-meeting"], { queryParams });
            });
    }

    public onTeamIdChanged() {
        this.fetching = true;
        this.resetMessage();
        this.updateMeetings()
            .pipe(
                finalize(() => this.fetching = false),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }

    private updateTeams() {
        return this.dataService.fetchTeamsWithMeetingsEnabled()
            .pipe(
                switchMap((teams) => {
                    this.teams = teams.filter((team) => team.EndDate === null);
                    if (!this.teams.length) {
                        this.setMessage("error", "Cannot link to a meeting as you are not a member of any teams with the meeting feature enabled.");
                        this.fetching = false;
                        return EMPTY;
                    }

                    this.teamId = this.teamId ?? this.teams[0]?.TeamId ?? undefined;

                    return of(teams);
                }),
                catchError((error) => this.handleException(error)),
                this.takeUntilDestroyed(),
            );
    }

    private updateMeetings() {
        return this.dataService.fetchUpcomingMeetings(this.teamId!)
            .pipe(
                tap((meetings) => {
                    this.meetings = meetings;
                    if (!this.meetings.length) {
                        this.setMessage("error", "There are no upcoming meetings in this team to link to.");
                    }

                    this.meetingId = this.meetings[0]?.MeetingId;
                }),
                catchError((error) => this.handleException(error)),
                this.takeUntilDestroyed(),
            );
    }
}
