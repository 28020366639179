import { Injectable } from "@angular/core";
import { IdentityService } from "./identity.service";
import { OfficeService } from "./office.service";

export interface IMeetingDetails {
    meetingId?: number,
    organisationId: number,
    teamId: number,
    name: string,
    startTime: string,
    endTime: string,
    attendees: string[],
    meetingAgendaTemplateId?: number,
}

@Injectable({
    providedIn: "root",
})
export class OutlookService {
    constructor(
        private identityService: IdentityService,
        private officeService: OfficeService,
    ) { }

    public async insertMeetingLink(link: string) {
        const body = await this.officeService.getBody();

        const parser = new DOMParser();
        const document = parser.parseFromString(body, "text/html");

        const anchor = document.createElement("a");
        anchor.href = link;
        anchor.innerText = "embedADAPT Meeting Link";

        // we can't find by id as Outlook tends to mess with id and class names
        const previousLink = document.querySelector(`a[href*="?meetingId="]`);
        if (previousLink) {
            previousLink.replaceWith(anchor);
        } else {
            document.body.prepend(anchor);
        }

        await this.officeService.setBody(document.body.outerHTML);
    }

    public async getMeetingDetails(organisationId: number, teamId: number): Promise<IMeetingDetails> {
        const title = await this.officeService.getTitle();
        const startDate = await this.officeService.getStartDate();
        const endDate = await this.officeService.getEndDate();
        const requiredAttendees = await this.officeService.getRequiredAttendees();
        const optionalAttendees = await this.officeService.getOptionalAttendees();
        const attendees = [
            this.identityService.email,
            ...requiredAttendees.map((attendee) => attendee.emailAddress),
            ...optionalAttendees.map((attendee) => attendee.emailAddress),
        ];

        return {
            organisationId,
            teamId,
            name: title,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
            attendees,
        };
    }

    public async getCurrentMeeting() {
        const body = await this.officeService.getBody();

        const regex = new RegExp(/\/([\w-]+)\/team\/([0-9]+)\/team-meetings\?meetingId=([0-9]+)/);
        const result = regex.exec(body);
        if (result) {
            const urlId = result[1];
            const teamId = Number(result[2]);
            const meetingId = Number(result[3]);

            return { urlId, teamId, meetingId };
        }
    }
}
