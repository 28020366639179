import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseWithMessageComponent } from "app/base-with-message/base-with-message.component";
import { DataService } from "app/data.service";
import { Meeting } from "app/models/meetings.model";
import { OutlookService } from "app/outlook.service";
import { EMPTY, of } from "rxjs";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-view-meeting",
    templateUrl: "./view-meeting-page.component.html",
    styleUrls: ["./view-meeting-page.component.scss"],
})
export class ViewMeetingPageComponent extends BaseWithMessageComponent implements OnInit {
    public urlId?: string;
    public teamId?: number;

    public meetingId?: number;

    public meeting?: Meeting;
    public meetingLink?: string;

    public fetching = true;
    public sending = false;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private outlookService: OutlookService,
    ) {
        super();
    }

    public ngOnInit() {
        this.route.queryParams
            .pipe(
                switchMap((params) => {
                    if (!params.urlId) {
                        return EMPTY;
                    }

                    this.urlId = params.urlId;
                    this.teamId = Number(params.teamId);
                    this.meetingId = Number(params.meetingId);
                    return of(true);
                }),
                switchMap(() => this.fetchMeeting()),
                switchMap(() => this.dataService.createMeetingLink(this.teamId!, this.meetingId!)),
                tap((meetingLink) => this.meetingLink = meetingLink),
                catchError((error) => this.handleException(error)),
                finalize(() => this.fetching = false),
                this.takeUntilDestroyed(),
            )
            .subscribe(() => this.fetching = false);
    }

    public async updateMeeting() {
        this.sending = true;
        this.resetMessage();

        const body = await this.outlookService.getMeetingDetails(this.dataService.organisationId!, this.teamId!);

        if (!body.name) {
            this.sending = false;
            this.setMessage("error", "Please add a title to the Outlook meeting");
            return;
        }

        body.meetingId = this.meetingId;
        this.dataService.updateMeeting(body)
            .pipe(
                switchMap(() => this.dataService.fetchMeetingById(this.meetingId!)),
                tap((meeting) => this.meeting = meeting),
                catchError((error) => this.handleException(error)),
                finalize(() => this.sending = false),
                this.takeUntilDestroyed(),
            )
            .subscribe(() => this.setMessage("success", "Meeting has been updated on embedADAPT"));
    }

    private fetchMeeting() {
        return this.dataService.fetchMeetingById(this.meetingId!)
            .pipe(
                tap((meeting) => {
                    if (!meeting) {
                        this.setMessage("error", "Meeting not found, it may be for a different organisation.");
                    }
                }),
                tap((meeting) => this.meeting = meeting),
            );
    }
}
