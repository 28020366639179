import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { SettingsService } from "./settings.service";

interface ILogInResponseBody {
    userName: string;
    access_token: string;
}

@Injectable({
    providedIn: "root",
})
export class IdentityService {
    private static PersonIdKey = "personId";
    private static EmailKey = "email";
    private static TokenKey = "token";

    public get personId() {
        return this.settings.getJSON(IdentityService.PersonIdKey);
    }

    public set personId(value: number) {
        this.settings.set(IdentityService.PersonIdKey, value);
    }

    public get email() {
        return this.settings.get(IdentityService.EmailKey);
    }

    public set email(value: string) {
        this.settings.set(IdentityService.EmailKey, value);
    }

    public get token() {
        return this.settings.get(IdentityService.TokenKey);
    }

    public set token(value: string | undefined) {
        this.settings.set(IdentityService.TokenKey, value);
    }

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) {
    }

    public login(email: string, password: string) {
        const params = new URLSearchParams();
        params.append("userName", email);
        params.append("password", password);
        params.append("grant_type", "password");

        const body = params.toString();

        const options = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        return this.http.post(`${environment.apiUrl}/Token`, body, options)
            .pipe(
                switchMap((response: ILogInResponseBody) => {
                    const token = response.access_token;

                    return of({ email, token });
                }),
            );
    }

    public logout() {
        // clear identity after logout, else the token won't be available for the interceptor
        return this.http.post(`${environment.apiUrl}/Account/Logout`, {}).pipe(
            tap(() => this.clearIdentity()),
        );
    }

    public clearIdentity() {
        this.token = undefined;

        this.settings.remove(IdentityService.EmailKey);
        this.settings.remove(IdentityService.TokenKey);

        return this.settings.save();
    }
}
