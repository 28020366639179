<div style="text-align: center;">
    <img src="/assets/logo-small.png"
         alt="adapt Logo"
         class="embedadapt-logo">

    <h1>embedADAPT Meeting</h1>

    <p>Add your meetings to embedADAPT</p>

    <button (click)="openLogInDialog()"
            data-test="start-button">
        Get Started
    </button>
</div>
