import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class SettingsService {
    public get(key: string) {
        return Office.context.roamingSettings.get(key);
    }

    public getJSON(key: string) {
        const str = this.get(key);
        return str !== undefined ? JSON.parse(str) : undefined;
    }

    public set(key: string, value: any) {
        Office.context.roamingSettings.set(key, value);
    }

    public remove(key: string) {
        Office.context.roamingSettings.remove(key);
    }

    public save() {
        return new Promise<Office.AsyncResultStatus>((resolve, reject) => {
            Office.context.roamingSettings.saveAsync((result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result.status);
                } else {
                    reject(result.error.message);
                }
            });
        });
    }
}
