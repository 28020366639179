<div>
    <img src="/assets/logo-small.png"
         style="padding-bottom: 20px;"
         alt="adapt logo">

    <h1>Login</h1>
    <p>Please login below using your embedADAPT credentials.</p>
    <section>
        <input type="email"
               placeholder="Email address"
               required
               [(ngModel)]="email"
               data-test="email">
    </section>

    <section>
        <input type="password"
               [(ngModel)]="password"
               placeholder="Password"
               required
               (keydown.enter)="login()"
               data-test="password">
    </section>

    <section>
        <button (click)="login()"
                data-test="login-button"
                class="login-button"
                [adaptLoading]="loading"><i class="fal fa-sign-in"></i> Login
        </button>
    </section>
</div>

<div>
    <p>To learn more about the embedADAPT platform, please <a href="https://www.adaptbydesign.com.au/"
           target="_blank">visit our website</a> or <a href="https://www.adaptbydesign.com.au/contact-us/"
           target="_blank">contact us</a>.</p>
</div>

<adapt-message-box [status]="status"
                   [message]="message"></adapt-message-box>
