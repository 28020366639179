import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class OfficeService {
    public getTitle() {
        return new Promise<string>((resolve, reject) => {
            Office.context.mailbox.item?.subject.getAsync((result) => this.handleAsyncResult(resolve, reject, result));
        });
    }

    public getStartDate() {
        return new Promise<Date>((resolve, reject) => {
            Office.context.mailbox.item?.start.getAsync((result) => this.handleAsyncResult(resolve, reject, result));
        });
    }

    public getEndDate() {
        return new Promise<Date>((resolve, reject) => {
            Office.context.mailbox.item?.end.getAsync((result) => this.handleAsyncResult(resolve, reject, result));
        });
    }

    public getRequiredAttendees() {
        return new Promise<Office.EmailAddressDetails[]>((resolve, reject) => {
            Office.context.mailbox.item?.requiredAttendees.getAsync((result) => this.handleAsyncResult(resolve, reject, result));
        });
    }

    public getOptionalAttendees() {
        return new Promise<Office.EmailAddressDetails[]>((resolve, reject) => {
            Office.context.mailbox.item?.optionalAttendees.getAsync((result) => this.handleAsyncResult(resolve, reject, result));
        });
    }

    public getBody() {
        return new Promise<string>((resolve, reject) => {
            Office.context.mailbox.item?.body.getAsync(Office.CoercionType.Html, (result) => this.handleAsyncResult(resolve, reject, result));
        });
    }

    public setBody(value: string) {
        return new Promise<void>((resolve, reject) => {
            Office.context.mailbox.item?.body.setAsync(value, { coercionType: Office.CoercionType.Html }, (result) => this.handleAsyncResult(resolve, reject, result));
        });
    }

    private handleAsyncResult = <T>(resolve: (value: T) => void, reject: (message: string) => void, result: Office.AsyncResult<T>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
            resolve(result.value);
        } else {
            reject(result.error.message);
        }
    };
}
