import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "app/base/base.component";
import { DataService } from "app/data.service";
import { IdentityService } from "app/identity.service";
import { Organisation } from "app/models/organisation.model";
import { SettingsService } from "app/settings.service";

@Component({
    selector: "adapt-settings-page",
    templateUrl: "./settings-page.component.html",
})
export class SettingsPageComponent extends BaseComponent implements OnInit {
    public organisations: Organisation[] = [];

    public organisationId = this.dataService.organisationId;

    public email = this.identityService.email;

    constructor(
        private router: Router,
        private dataService: DataService,
        private identityService: IdentityService,
        private settings: SettingsService,
    ) {
        super();
    }

    public ngOnInit() {
        this.dataService.fetchOrganisations()
            .pipe(
                this.takeUntilDestroyed(),
            )
            .subscribe((organisations) => this.organisations = organisations);
    }

    public async save() {
        this.dataService.organisationId = Number(this.organisationId);
        this.dataService.organisationId$.next(this.organisationId);
        await this.settings.save();

        this.router.navigate(["home"]);
    }

    public logout() {
        this.identityService.logout()
            .pipe(
                this.takeUntilDestroyed(),
            )
            .subscribe(() => {
                this.router.navigate(["/welcome"]);
            });
    }

    public navigateToDebugPage() {
        this.router.navigateByUrl("debug");
    }
}
