import { Directive, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive()
export abstract class BaseComponent implements OnDestroy {
    private destroyed$ = new Subject<void>();

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    protected takeUntilDestroyed<T>() {
        return takeUntil<T>(this.destroyed$);
    }
}
