<h1 class="mt-2">Connect your Outlook meetings to embedADAPT</h1>

<a routerLink="/create-meeting"
   class="primary-box"
   data-test="create-meeting-button">
    <div><i class="fal fa-plus-circle"></i> Create embedADAPT Meeting</div>
</a>

<a routerLink="/insert-link"
   class="primary-box"
   data-test="link-meeting-button">
    <div><i class="fal fa-link"></i> Link to existing embedADAPT Meeting</div>
</a>

<a routerLink="/view-meeting"
   [queryParams]="queryParams"
   *ngIf="queryParams"
   class="secondary-box"
   data-test="view-meeting-button">
    <div><i class="fal fa-eye"></i> View Current Meeting</div>
</a>
