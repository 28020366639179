import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject, forkJoin, map, of, throwError, timeout } from "rxjs";
import { filter, mergeMap, switchMap, toArray } from "rxjs/operators";
import { IdentityService } from "./identity.service";
import { ConnectionType } from "./models/connection-type.enum";
import { FeatureStatus } from "./models/feature-status.model";
import { MeetingAgendaTemplate } from "./models/meeting-agenda-template.model";
import { Meeting } from "./models/meetings.model";
import { Organisation } from "./models/organisation.model";
import { Person } from "./models/person.model";
import { Team } from "./models/team.model";
import { SettingsService } from "./settings.service";

interface IBreezeResponse<T> {
    Results: T[];
}

@Injectable({
    providedIn: "root",
})
export class DataService {
    private static OrganisationIdKey = "organisation";

    private static readonly HttpTimeOut = 10000;
    private static readonly HttpTimeOutError = "Unable to reach server - please check your network connection";

    public organisationId$ = new BehaviorSubject(this.organisationId);

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
        private identityService: IdentityService,
    ) { }

    private getBreezeParams(params: object, otherParams?: {
        [param: string]: string | number | boolean | readonly (string | number | boolean)[]
    }) {
        return encodeURIComponent(JSON.stringify(params)) + (otherParams ? "&" + new HttpParams().appendAll(otherParams) : "");
    }

    public get organisationId() {
        return this.settings.getJSON(DataService.OrganisationIdKey);
    }

    public set organisationId(value: number | undefined) {
        this.settings.set(DataService.OrganisationIdKey, value);
    }

    public fetchPerson() {
        const params = this.getBreezeParams({ expand: ["Preferences"] });
        return this.http.get<IBreezeResponse<Person>>(`${environment.apiUrl}/Methodology/Person?${params}`)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
                switchMap(({ Results }) => of(Results[0])),
            );
    }

    public fetchOrganisations() {
        const params = this.getBreezeParams({}, { allowStakeholderOverride: false });
        return this.http.get<Organisation[]>(`${environment.apiUrl}/Methodology/Organisations?${params}`);
    }

    public fetchMeetingById(meetingId: number) {
        const params = this.getBreezeParams(
            { where: { MeetingId: meetingId }, expand: ["MeetingAgendaItems"] },
            { organisationId: String(this.organisationId) });
        return this.http.get<IBreezeResponse<Meeting>>(`${environment.apiUrl}/Methodology/Meetings?${params}`)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
                switchMap(({ Results }) => of(Results[0])),
                switchMap((meeting) => {
                    if (!meeting) {
                        return of(undefined);
                    }

                    meeting.MeetingAgendaItems.sort((a, b) => a.Ordinal - b.Ordinal);

                    return of({ ...meeting, MeetingDateTime: new Date(meeting.MeetingDateTime) } as Meeting);
                }),
            );
    }

    public fetchUpcomingMeetings(teamId: number) {
        // only fetch teams that have where user has an active role connection
        const params = this.getBreezeParams({
            where: {
                and: {
                    TeamId: teamId,
                    Status: "NotStarted",
                },
            },
            expand: ["MeetingAgendaItems"],
        }, { organisationId: String(this.organisationId) });

        return this.http.get<IBreezeResponse<Meeting>>(`${environment.apiUrl}/Methodology/Meetings?${params}`)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
                map(({ Results }) => Results),
                switchMap((meetings) => of(meetings.map((meeting) => ({ ...meeting, MeetingDateTime: new Date(meeting.MeetingDateTime) })))),
            );
    }

    public fetchDefaultOrganisation(person: Person) {
        if (person.Preferences.DefaultOrganisationId) {
            return of(person.Preferences.DefaultOrganisationId);
        }

        return this.fetchOrganisations()
            .pipe(
                switchMap((organisations) => of(organisations[0].OrganisationId)),
            );
    }

    public fetchTeamsWithMeetingsEnabled() {
        return this.fetchTeams()
            .pipe(
                mergeMap((teams) => forkJoin([of(teams), this.fetchFeatureStatuses()])),
                switchMap(([teams, featureStatuses]) => of(teams.filter((team) => this.isMeetingsEnabled(team, featureStatuses)))),
            );
    }

    public fetchMeetingAgendaTemplates() {
        const params = this.getBreezeParams({}, { organisationId: String(this.organisationId) });
        return this.http.get<MeetingAgendaTemplate[]>(`${environment.apiUrl}/Methodology/MeetingAgendaTemplates?${params}`)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
            );
    }

    public createMeetingLink(teamId: number, meetingId: number) {
        return this.fetchOrganisations()
            .pipe(
                switchMap((organisations) => {
                    const urlId = organisations.filter((organisation) => organisation.OrganisationId === this.organisationId)[0].UrlIdentifier;

                    return of(`${environment.embedUrl}/${urlId}/team/${teamId}/team-meetings?meetingId=${meetingId}`);
                }),
            );
    }

    public addMeeting(body: object) {
        return this.http.post<number>(`${environment.apiUrl}/MethodologyServices/AddMeeting`, body)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
            );
    }

    public updateMeeting(body: object) {
        return this.http.put<Meeting>(`${environment.apiUrl}/MethodologyServices/UpdateMeeting`, body)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
            );
    }

    private fetchFeatureStatuses() {
        const params = this.getBreezeParams({}, { organisationId: String(this.organisationId) });
        return this.http.get<FeatureStatus[]>(`${environment.apiUrl}/Methodology/FeatureStatuses?${params}`)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
            );
    }

    private isMeetingsEnabled(team: Team, featureStatuses: FeatureStatus[]) {
        // we could fetch the actual feature ids here, but its never going to change and it would be an unneceesary piece of network traffic
        const MeetingFeatureId = 28;
        const KanbanFeatureId = 31;

        const array = featureStatuses.filter((status) => status.TeamId === team.TeamId && (status.FeatureId === MeetingFeatureId || status.FeatureId === KanbanFeatureId));
        return array.length >= 2;
    }

    private fetchTeams() {
        return this.isCoach()
            .pipe(
                switchMap((isCoach) => isCoach ? this.fetchTeamsForCoach() : this.fetchTeamsForNonCoach()),
            );
    }

    private fetchTeamsForNonCoach() {
        // only fetch teams that have where user has an active role connection
        const params = this.getBreezeParams({
            where: {
                RoleConnections: {
                    any: {
                        and: {
                            ["Connection.PersonId"]: this.identityService.personId,
                            EndDate: null,
                        },
                    },
                },
            },
        }, { organisationId: String(this.organisationId) });

        return this.http.get<IBreezeResponse<Team>>(`${environment.apiUrl}/Methodology/Teams?${params}`)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
                map((resp) => resp.Results),
            );
    }

    private fetchTeamsForCoach() {
        const params = this.getBreezeParams({}, { organisationId: String(this.organisationId) });

        // fetch all teams
        return this.http.get<Team[]>(`${environment.apiUrl}/Methodology/Teams?${params}`)
            .pipe(
                timeout({
                    each: DataService.HttpTimeOut,
                    with: () => throwError(() => new Error(DataService.HttpTimeOutError)),
                }),
            );
    }

    public isCoach() {
        const params = this.getBreezeParams({ expand: ["Connections"] });
        return this.http.get<IBreezeResponse<Person>>(`${environment.apiUrl}/Methodology/Person?${params}`)
            .pipe(
                switchMap(({ Results }) => {
                    const person = Results[0];
                    const connections = person?.Connections ?? [];
                    return connections;
                }),
                filter((connection) => connection.OrganisationId === this.organisationId),
                filter((connection) => !connection.EndDate),
                toArray(),
                switchMap((connections) => {
                    const isCoach = connections.some((connection) => connection.ConnectionType === ConnectionType.Coach);
                    return of(isCoach);
                }),
            );
    }
}
