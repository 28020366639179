import { Directive, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
    selector: "[adaptHotkey]",
})
export class HotkeyDirective {
    @Output() public adaptHotkey = new EventEmitter();
    @Input() public set adaptHotkeySequence(value: string) {
        this.sequence = value.split("");
    }

    private sequence?: string[];
    private queue: string[] = [];

    @HostListener("window:keyup", ["$event"])
    public handleKeyup(event: KeyboardEvent) {
        this.queue.push(event.key);

        if (this.isQueueOverloaded) {
            this.queue.shift();
        }

        if (this.isCorrect) {
            this.adaptHotkey.emit();
        }
    }

    private get isQueueOverloaded() {
        return this.queue.length > (this.sequence?.length ?? 0);
    }

    private get isCorrect() {
        return this.sequence?.every((value, index) => value === this.queue[index]);
    }
}
