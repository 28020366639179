<a routerLink="/home"
   class="link-button"
   data-test="back-button"><i class="fal fa-chevron-left"></i> Back</a>
<h1>Current Meeting</h1>
<p>This shows the embedADAPT meeting currently associated with this Outlook meeting.</p>
<div class="container"
     *ngIf="meeting">
    <h3>Meeting Name: {{meeting.Name}}</h3>

    <div *ngIf="meeting.MeetingAgendaItems?.length"
         class="meeting-agenda-items">
        <h4>Agenda</h4>
        <ul>
            <li *ngFor="let meetingAgendaItem of meeting.MeetingAgendaItems">{{meetingAgendaItem.Name}}</li>
        </ul>
    </div>

    <div class="mt-2">
        <a [href]="meetingLink"
           target="_blank"
           data-test="current-meeting-link"><i class="fal fa-external-link-alt"></i> embedADAPT meeting link</a>
    </div>
    <button class="d-block mt-2"
            [adaptLoading]="sending"
            [adaptLoadingDisabled]="sending || fetching"
            (click)="updateMeeting()">
        <i class="fal fa-cloud-upload-alt"></i> Update embedADAPT Meeting</button>
</div>

<p *ngIf="fetching"
   [adaptLoading]="fetching">
    Loading...
</p>

<adapt-message-box [status]="status"
                   [message]="message"></adapt-message-box>
