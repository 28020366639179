import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseWithMessageComponent } from "app/base-with-message/base-with-message.component";
import { DataService } from "app/data.service";
import { MeetingAgendaTemplate } from "app/models/meeting-agenda-template.model";
import { Team } from "app/models/team.model";
import { OutlookService } from "app/outlook.service";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-create-meeting-page",
    templateUrl: "./create-meeting-page.component.html",
})
export class CreateMeetingPageComponent extends BaseWithMessageComponent {
    public teams?: Team[];

    public teamId?: number;
    public meetingAgendaTemplateId?: number;
    public meetingId?: number;

    public fetching = true;
    public sending = false;

    public meetingAgendaTemplates?: Map<string, MeetingAgendaTemplate[]>;
    private rawMeetingAgendaTemplates?: MeetingAgendaTemplate[];

    constructor(
        private outlookService: OutlookService,
        private dataService: DataService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
    ) {
        super();

        this.dataService.organisationId$
            .pipe(
                tap(() => this.teamId = undefined),
                switchMap(() => this.updateTeams()),
                switchMap(() => this.fetchMeetingAgendaTemplates()),
                tap(() => this.changeDetectorRef.detectChanges()),
                tap({
                    next: () => this.fetching = false,
                    error: () => this.fetching = false,
                }),
                this.takeUntilDestroyed(),
            )
            .subscribe();
    }

    public originalMapOrderFn = () => 0;

    public get disabled() {
        return this.teams?.length === 0;
    }

    public async createMeeting() {
        const body = await this.outlookService.getMeetingDetails(this.dataService.organisationId!, this.teamId!);
        body.meetingAgendaTemplateId = this.meetingAgendaTemplateId!;

        this.resetMessage();
        if (!body.name) {
            this.setMessage("error", "Please add a title to the Outlook meeting");
            return;
        }

        this.sending = true;
        this.dataService.addMeeting(body)
            .pipe(
                tap((meetingId) => this.meetingId = meetingId),
                switchMap(() => this.dataService.createMeetingLink(this.teamId!, this.meetingId!)),
                switchMap((link) => this.outlookService.insertMeetingLink(link)),
                catchError((error) => this.handleException(error)),
                finalize(() => this.sending = false),
                this.takeUntilDestroyed(),
            )
            .subscribe(async () => {
                this.setMessage("success", "Your meeting has been created on the embedADAPT platform.");
                const queryParams = await this.outlookService.getCurrentMeeting();
                await this.router.navigate(["/view-meeting"], { queryParams });
            });
    }

    public updateTemplatesDisplay() {
        this.meetingAgendaTemplates = this.getGroupedMeetingAgendaTemplates(this.rawMeetingAgendaTemplates!);
    }

    private updateTeams() {
        return this.dataService.fetchTeamsWithMeetingsEnabled()
            .pipe(
                tap((teams) => {
                    this.teams = teams.filter((team) => team.EndDate === null);
                    if (!this.teams.length) {
                        this.setMessage("error", "Can not create a meeting as you are not a member of any teams with the meeting feature enabled.");
                    }

                    this.teamId = this.teamId ?? this.teams[0]?.TeamId ?? undefined;
                }),
                catchError((error) => this.handleException(error)),
            );
    }

    private fetchMeetingAgendaTemplates() {
        return this.dataService.fetchMeetingAgendaTemplates()
            .pipe(
                tap((meetingAgendaTemplates) => {
                    this.rawMeetingAgendaTemplates = meetingAgendaTemplates;
                    this.meetingAgendaTemplates = this.getGroupedMeetingAgendaTemplates(this.rawMeetingAgendaTemplates);
                }),
                catchError((error) => this.handleException(error)),
            );
    }

    private getGroupedMeetingAgendaTemplates(templates: MeetingAgendaTemplate[]) {
        const mapping = new Map<string, MeetingAgendaTemplate[]>();

        // sort templates in this order:
        //   1. currently selected team
        //   2. organisation
        //   3. other teams
        templates = templates.reduce((acc, t) => {
            if (t.TeamId === this.teamId && t.TeamId !== null) {
                acc[0].push(t);
            } else if (t.TeamId === null) {
                acc[1].push(t);
            } else {
                acc[2].push(t);
            }
            return acc;
        }, [[], [], []] as MeetingAgendaTemplate[][]).flat();

        for (const template of templates) {
            const team = this.teams?.find((t) => t.TeamId === template.TeamId);
            const key = template.TeamId
                ? "Team: " + (team?.Name ?? "Unknown Team")
                : "Organisation";

            if (!mapping.has(key)) {
                mapping.set(key, []);
            }
            mapping.get(key)!.push(template);
        }

        return mapping;
    }
}