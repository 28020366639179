import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

export enum DialogEvent {
    LogIn = "LogIn",
}

export type DialogArgument = IDialogSuccessArgument | IDialogFailureArgument;

export interface IDialogSuccessArgument {
    message: string;
}

export interface IDialogFailureArgument {
    error: number;
}

export interface IDialogMessage {
    event: DialogEvent;
    body: any;
}

export const isSuccess = (value: DialogArgument): value is IDialogSuccessArgument => {
    // eslint-disable-next-line no-prototype-builtins
    return value.hasOwnProperty("message");
};

@Injectable({
    providedIn: "root",
})
export class DialogService {
    public openDialog(path: string) {
        return new Promise<Office.Dialog>((resolve, reject) => {
            const options = { width: 30, height: 45, displayInIframe: true };

            Office.context.ui.displayDialogAsync(`${environment.clientUrl}/#/${path}`, options, (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result.value);
                } else {
                    reject(result.error);
                }
            });
        });
    }

    public async getDialogMessage(dialog: Office.Dialog) {
        return new Promise<string>((resolve, reject) => {
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: DialogArgument) => {
                if (isSuccess(arg)) {
                    resolve(arg.message);
                } else {
                    reject(arg.error);
                }
            });
        });
    }
}
