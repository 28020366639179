import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "app/base/base.component";
import { OutlookService } from "app/outlook.service";


/**
 *  This component acts as a landing page that will only be hit on the initial load.
 *  It acts as a wrapper that will either forward the user on to the home page or
 *  redirect them to the current meeting page. This is required as if we have this
 *  logic on the home page, it will do the redirect each time the user navigates back.
 */

@Component({
    selector: "adapt-taskpane-page",
    template: "",
})
export class TaskpanePageComponent extends BaseComponent implements OnInit {
    constructor(
        private outlookService: OutlookService,
        private router: Router,
    ) {
        super();
    }

    public async ngOnInit() {
        const queryParams = await this.outlookService.getCurrentMeeting();

        if (queryParams) {
            await this.router.navigate(["/view-meeting"], { queryParams });
        } else {
            await this.router.navigate(["/home"]);
        }
    }
}
