import { Directive } from "@angular/core";
import { BaseComponent } from "app/base/base.component";
import { MessageStatus } from "app/message-box/message-box.component";
import { throwError } from "rxjs";

@Directive()
export abstract class BaseWithMessageComponent extends BaseComponent {
    public status?: MessageStatus;
    public message?: string;

    protected resetMessage() {
        this.status = undefined;
        this.message = undefined;
    }

    protected setMessage(status: MessageStatus, value: string) {
        this.status = status;
        this.message = value;
    }

    protected handleException(error: Error) {
        this.setMessage("error", error.message ? error.message : "Unknown error");
        return throwError(() => error);
    }
}
