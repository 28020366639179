<a routerLink="/home"
   class="link-button"><i class="fal fa-chevron-left"></i> Back</a>
<h1>Link to existing embedADAPT meeting</h1>
<div class="container">
    <label>Team</label>
    <select [(ngModel)]="teamId"
            (ngModelChange)="onTeamIdChanged()"
            [disabled]="fetching"
            data-test="team-selector">
        <option *ngFor="let team of teams"
                [value]="team.TeamId">{{team.Name}}</option>
    </select>
    <label>Meeting</label>
    <select [(ngModel)]="meetingId"
            [disabled]="fetching"
            data-test="meeting-selector">
        <option *ngFor="let meeting of meetings"
                [value]="meeting.MeetingId">{{meeting.Name}} - {{meeting.MeetingDateTime.toDateString()}}</option>
    </select>
    <button (click)="insertLink()"
            [adaptLoading]="sending"
            [adaptLoadingDisabled]="sending || fetching || !meetingId || !teamId"
            data-test="add-link-button"><i class="fal fa-link"></i> Add link to Outlook meeting description</button>
</div>

<p *ngIf="fetching"
   [adaptLoading]="fetching">
    Loading...
</p>

<adapt-message-box [status]="status"
                   [message]="message"></adapt-message-box>
