import { Component } from "@angular/core";
import { buildInfo } from "environments/build-info";

@Component({
    selector: "adapt-debug-page",
    templateUrl: "./debug-page.component.html",
})
export class DebugPageComponent {
    public buildDetails = buildInfo;

    public createError() {
        throw new Error("This is a test error");
    }
}
