import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IdentityService } from "./identity.service";

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {

    constructor(
        private identityService: IdentityService,
        private router: Router,
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const baseUri = environment.apiUrl;
        if (request.url.slice(0, baseUri.length) === baseUri) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.identityService.token}`,
                },
            });
        }

        return next.handle(request).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.handleUnauthorized();
                }

                return throwError(() => err);
            }),
        );
    }

    private async handleUnauthorized() {
        await this.identityService.clearIdentity();
        this.router.navigate(["/taskpane"]);
    }
}
