import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "app/base/base.component";
import { DataService } from "app/data.service";
import { DialogEvent, DialogService, IDialogMessage } from "app/dialog.service";
import { IdentityService } from "app/identity.service";
import { SettingsService } from "app/settings.service";
import { forkJoin, of } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-welcome-page",
    templateUrl: "./welcome-page.component.html",
})
export class WelcomePageComponent extends BaseComponent {
    constructor(
        private settings: SettingsService,
        private identityService: IdentityService,
        private dataService: DataService,
        private dialogService: DialogService,
        private router: Router,
    ) {
        super();
    }

    public async openLogInDialog() {
        const dialog = await this.dialogService.openDialog("login");

        const message = await this.dialogService.getDialogMessage(dialog);

        const { event, body }: IDialogMessage = JSON.parse(message);

        switch (event) {
            case DialogEvent.LogIn:
                await this.login(body);
                dialog.close();
                break;

            default:
                console.error(`Unknown event: ${event}`);
        }
    }

    private async login(body: any) {
        this.identityService.email = body.email;
        this.identityService.token = body.token;
        await this.settings.save();

        this.dataService.fetchPerson()
            .pipe(
                mergeMap((person) => forkJoin([of(person.PersonId), this.dataService.fetchDefaultOrganisation(person)])),
                switchMap(([personId, organisationId]) => {
                    this.identityService.personId = personId;
                    this.dataService.organisationId = organisationId;
                    return this.settings.save();
                }),
                this.takeUntilDestroyed(),
            )
            .subscribe(() => this.router.navigate(["/taskpane"]));
    }
}
