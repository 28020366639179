import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "app/base/base.component";

@Component({
    selector: "adapt-footer",
    templateUrl: "./footer.component.html",
})
export class FooterComponent extends BaseComponent {

    constructor(
        private router: Router,
    ) {
        super();
    }

    public async navigateToSettingsPage() {
        this.router.navigate(["settings"]);
    }
}
