import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { buildInfo } from "environments/build-info";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

Office.initialize = async () => {
    Sentry.init({
        dsn: environment.SentryDsn,
        release: buildInfo.sourceTag,
        environment: environment.AdaptEnvironmentName,
        enabled: environment.AdaptEnvironmentName !== "Local",
    });

    Sentry.setExtras({
        "Source Hash": buildInfo.sourceHash,
        "Source Date": buildInfo.sourceDate,
    });

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch((err) => console.error(err));
};
