import { NgModule } from "@angular/core";
import { mapToCanActivateChild, RouterModule, Routes } from "@angular/router";
import { AuthenticatedGuard } from "./authenticated.guard";
import { CreateMeetingPageComponent } from "./create-meeting-page/create-meeting-page.component";
import { DebugPageComponent } from "./debug-page/debug-page.component";
import { FooterComponent } from "./footer/footer.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { InsertLinkPageComponent } from "./insert-link-page/insert-link-page.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import { SettingsPageComponent } from "./settings-page/settings-page.component";
import { TaskpanePageComponent } from "./taskpane-page/taskpane-page.component";
import { ViewMeetingPageComponent } from "./view-meeting-page/view-meeting-page.component";
import { WelcomePageComponent } from "./welcome-page/welcome-page.component";

const routes: Routes = [
    {
        path: "welcome",
        component: WelcomePageComponent,
    },
    {
        path: "login",
        component: LoginPageComponent,
    },
    {
        path: "settings",
        component: SettingsPageComponent,
    },
    {
        path: "debug",
        component: DebugPageComponent,
    },
    {
        path: "",
        component: FooterComponent,
        canActivateChild: mapToCanActivateChild([AuthenticatedGuard]),
        children: [
            {
                path: "",
                redirectTo: "taskpane",
                pathMatch: "full",
            },
            {
                path: "taskpane", // this path is defined in the manifest, do not change it
                component: TaskpanePageComponent,
            },
            {
                path: "home",
                component: HomePageComponent,
            },
            {
                path: "create-meeting",
                component: CreateMeetingPageComponent,
            },
            {
                path: "insert-link",
                component: InsertLinkPageComponent,
            },
            {
                path: "view-meeting",
                component: ViewMeetingPageComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
