import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "app/base/base.component";
import { OutlookService } from "app/outlook.service";

interface IMeetingQueryParams {
    urlId: string;
    teamId: number;
    meetingId: number;
}

@Component({
    selector: "adapt-home-page",
    templateUrl: "./home-page.component.html",
})
export class HomePageComponent extends BaseComponent implements OnInit {
    public queryParams?: IMeetingQueryParams;

    public loading = true;

    constructor(
        private outlookService: OutlookService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.queryParams = await this.outlookService.getCurrentMeeting();
    }
}
