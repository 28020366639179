<div (adaptHotkey)="navigateToDebugPage()"
     adaptHotkeySequence="DEBUG">
    <a routerLink="/home"
       class="link-button"
       data-test="back-button"><i class="fal fa-chevron-left"></i> Back</a>

    <h1>Settings</h1>

    <div>
        <h2>Current Organisation</h2>

        <select [(ngModel)]="organisationId"
                data-test="select-current-organisation">
            <option *ngFor="let organisation of organisations"
                    [value]="organisation.OrganisationId">{{organisation.Name}}
            </option>
        </select>

        <button (click)="save()">Save &amp; close</button>
    </div>

    <hr>

    <div>
        <h2>Account</h2>

        <p>You are logged in as: {{email}}</p>

        <button (click)="logout()"
                data-test="logout-button"
                class="danger">Log out</button>
    </div>
</div>
