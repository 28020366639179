<a routerLink="/home"
   class="link-button">
    <i class="fal fa-chevron-left"></i> Back
</a>

<h1>Create embedADAPT Meeting</h1>

<div class="container">
    <label>Team</label>
    <select [ngModel]="teamId"
            (ngModelChange)="teamId = $event; updateTemplatesDisplay()"
            [disabled]="fetching"
            data-test="team-selector">
        <option *ngFor="let team of teams"
                [ngValue]="team.TeamId">{{team.Name}}</option>
    </select>

    <label>Meeting agenda template</label>
    <select [(ngModel)]="meetingAgendaTemplateId"
            [disabled]="fetching"
            data-test="meeting-agenda-template-selector">
        <ng-container *ngFor="let teams of meetingAgendaTemplates | keyvalue: originalMapOrderFn">
            <optgroup label="{{teams.key}}">
                <option *ngFor="let meetingAgendaTemplate of teams.value"
                        [ngValue]="meetingAgendaTemplate.MeetingAgendaTemplateId">{{meetingAgendaTemplate.Name}}</option>
            </optgroup>
        </ng-container>

        <option [value]="undefined"> -- None -- </option>
    </select>

    <button (click)="createMeeting()"
            [adaptLoading]="sending"
            [adaptLoadingDisabled]="sending || fetching || !teamId"
            data-test="create-meeting-button"><i class="fal fa-plus-circle"></i> Create meeting in embedADAPT</button>
</div>

<p *ngIf="fetching"
   [adaptLoading]="fetching">
    Loading...
</p>


<adapt-message-box [status]="status"
                   [message]="message"></adapt-message-box>
