import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[adaptLoading]",
})
export class LoadingDirective implements OnChanges {
    @Input() public adaptLoading = false;
    @Input() public adaptLoadingDisabled: boolean | undefined;

    private icon: any;

    constructor(private renderer: Renderer2, private hostElement: ElementRef) {
        this.icon = this.renderer.createElement("i");
        this.renderer.addClass(this.icon, "fa");
        this.renderer.addClass(this.icon, "fa-spinner");
        this.renderer.addClass(this.icon, "fa-spin");
        this.icon.style.paddingRight = "5px";
        this.renderer.appendChild(this.hostElement.nativeElement, this.icon);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.adaptLoading) {
            const loading = changes.adaptLoading.currentValue;

            if (loading) {
                this.setDisabled(true);
                this.icon.style.display = "inline-block";
            } else {
                this.setDisabled(false);
                this.icon.style.display = "none";
            }
        }

        if (changes.adaptLoadingDisabled) {
            this.setDisabled(changes.adaptLoadingDisabled.currentValue, true);
        }
    }

    private get disableAutomatically() {
        return this.adaptLoadingDisabled === undefined;
    }

    private setDisabled(value: boolean, manualOverride = false) {
        if (this.disableAutomatically || manualOverride) {
            this.hostElement.nativeElement.disabled = value;
        }
    }
}
